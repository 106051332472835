const ApiBaseURL = process.env.REACT_APP_APIURL;
const Environmen = process.env.REACT_APP_ENVIRONMENT; 

export const ApiBasePath = {
  Merchant          :"/merchant/service/api",
  Product           :'/product/service',
  Backofficebff     :ApiBaseURL?.includes("localhost") ? "/api/v1" : "/mpxbff/service/api/v1",

};

const jsonServerBasePath= "http://localhost:4000/";

class ApiURL {
  static Query = {
  
  GetMenuList                                 : ApiBasePath.Backofficebff         + '/generals/leftmenu',
  GetCheckOperatorList                        : ApiBasePath.Backofficebff         + '/generals/check-operator-exist',
  GetCommentTicketList                        : ApiBasePath.Backofficebff         + '/product-comments/ticket-list',
  GetCommentStatusList                        : ApiBasePath.Backofficebff         + '/product-comments/approvestatus-list',
  GetCommentRejectReasonList                  : ApiBasePath.Backofficebff         + '/product-comments/rejectreason-list',
  GetCommentData                              : ApiBasePath.Backofficebff         + '/product-comments/list',
  GetCommentDataById                          : ApiBasePath.Backofficebff         + '/product-comments/single',
  GetOperatorService                          : ApiBasePath.Backofficebff         +  '/operator/operators-combinedid', 
  GetOperatorList                             : ApiBasePath.Backofficebff         + '/operator/operators',
  getOperatorListByRoleId                     : ApiBasePath.Backofficebff         + '/operator',
  GetStoreSaleOrderByOrderNo                  : ApiBasePath.Backofficebff         + '/store/sale-orders/by-orderno',
  GetStoreInvoiceFile                         : ApiBasePath.Backofficebff         + '/store/invoice-file',
  GetRolesList                                : ApiBasePath.Backofficebff         + '/operator/role',
  GetOperatorUpdate                           : ApiBasePath.Backofficebff         + '/operator/update',
  GetMenuItemsByParent                        : ApiBasePath.Backofficebff         + '/menu/items-byparent?parentMenuId=0&regionId=1&applicationId=1',
  GetMenuAll                                  : ApiBasePath.Backofficebff         + '/menu/all?regionId=1&applicationId=1',
  GetMenuRoleList                             : ApiBasePath.Backofficebff         + '/menu/item-role/list',

  GetPaymentLogsListCustomerId                : ApiBasePath.Backofficebff         + '/paymentlogs/customer',
  GetPaymentLogsListOrderNumber               : ApiBasePath.Backofficebff         + '/paymentlogs/order',

  GetTransactionList                          : ApiBasePath.Backofficebff         + '/Transaction/transactions',
  GetActivityList                             : ApiBasePath.Backofficebff         + '/ActivityLog/activity',
  GetActivityLogsById                         : ApiBasePath.Backofficebff         + '/ActivityLog/activityLogs',
  TransactionExportToExcel                    : ApiBasePath.Backofficebff         + '/Export/transactionExportToExcel',
  GetDocumentByOrderNumber                    : ApiBasePath.Backofficebff         + '/Document/GetDocumentByOrderNumber',
  TransactionExportToCsv                      : ApiBasePath.Backofficebff         + '/Export/TransactionExportToCsv',
  TransactionOrderExportToExcel               : ApiBasePath.Backofficebff         + '/Export/TransactionOrderExportToExcel',
  TransactionOrderExportToCsv                 : ApiBasePath.Backofficebff         + '/Export/TransactionOrderExportToCsv',
  DownloadProductFileExport                   : ApiBasePath.Backofficebff         + '/Product/export',
  ManuelRetransmission                        : ApiBasePath.Backofficebff         + '/Transaction/ManuelRetransmission',
  GetSaleAndReturnDashboard                   : ApiBasePath.Backofficebff         + '/Dashboard/saleAndReturnDashboard',
  GetCountryTransferStatistics                : ApiBasePath.Backofficebff         + '/Dashboard/countryTransferStatistics',
  GetActivityData                             : ApiBasePath.Backofficebff         + '/Dashboard/activityData',
  GetSaleAndReturnTotalDashboard              : ApiBasePath.Backofficebff         + '/Dashboard/saleAndReturnTotalDashboard',
  GetDataForFilter                            : ApiBasePath.Backofficebff         + '/Dashboard/getDataForFilter',
  GetOrdersTotalDashboard                     : ApiBasePath.Backofficebff         + '/Dashboard/ordersDashboard',
  GetSaleAndReturnQuantitylDashboard          : ApiBasePath.Backofficebff         + '/Dashboard/saleAndReturnQuantityDashboard',
  GetOrdersQuantityDashboard                  : ApiBasePath.Backofficebff         + '/Dashboard/ordersQuantityDashboard',
  GetOrderList                                : ApiBasePath.Backofficebff         + '/Orders/ordersList',
  GetProductList                              : ApiBasePath.Backofficebff         + '/Product/importedProductList',
  GetProductSentLogList                       : ApiBasePath.Backofficebff         + '/Product/ProductSentLogs',
  DownloadProductExcel                        : ApiBasePath.Backofficebff         + '/Product/downloadProductExcel',
  GetMpList                                   : ApiBasePath.Backofficebff         + '/LookUp/getMpList',
  GetMpCountryList                            : ApiBasePath.Backofficebff         + '/LookUp/getMpCountryList',
  GetMpIntegratorList                         : ApiBasePath.Backofficebff         + '/LookUp/getMpIntegratorList',
  GetMpOrderStatusList                        : ApiBasePath.Backofficebff         + '/LookUp/getMpOrderStatusList',
  GetMpOriginCodeList                         : ApiBasePath.Backofficebff         + '/LookUp/getMpOriginCodeList',
  GetMpOriginsFromIntegration                 : ApiBasePath.Backofficebff         + '/LookUp/getMpOriginsFromIntegration',
  GetMpOriginsFromSales                       : ApiBasePath.Backofficebff         + '/LookUp/getMpOriginsFromSales',
  };

  static Command = {
   PostCommentAssignToMe                      : ApiBasePath.Backofficebff         + '/product-comments/assigntome',
   PostCommentTicketAdd                       : ApiBasePath.Backofficebff         + '/product-comments/ticket-list/add',
   DeleteCommentTicket                        : ApiBasePath.Backofficebff         + '/product-comments/ticket-list', 
   PostCommentStatusUpdate                    : ApiBasePath.Backofficebff         + '/product-comments/status-update',
   PostMenuAdd                                : ApiBasePath.Backofficebff         + '/menu/item/add',
   PostMenuItemRoleAdd                        : ApiBasePath.Backofficebff         + '/menu/item-role/add',
   PutMenuUpdate                              : ApiBasePath.Backofficebff         + '/menu/item/update',   
   DeleteMenu                                 : ApiBasePath.Backofficebff         + '/menu/item',
   PutOperatorUpdate                          : ApiBasePath.Backofficebff         + '/operator/update',
   PostAddOperatorToRole                      : ApiBasePath.Backofficebff         + '/operator/addoperatorrole',
   PostOperatorCreate                         : ApiBasePath.Backofficebff         + '/operator/create',
   DeleteOperatorFromRole                     : ApiBasePath.Backofficebff         + '/operator/removefromrole',
   ExcelImport                                : ApiBasePath.Backofficebff         + '/MpExcel/ExcelImportServices',
   ImportSalesExcel                           : ApiBasePath.Backofficebff         + '/MpExcel/SalesExcelImport',
   SendFileToMarketPlace                      : ApiBasePath.Backofficebff         + '/Product/sync',

  };
}

export { ApiBaseURL, ApiURL };
